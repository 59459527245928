<script lang="ts" setup>
import { useReCaptcha } from 'vue-recaptcha-v3'
import useVuelidate from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators'
import type { SocmedError } from '~/models/Auth'
import { useAuth } from '~/store/auth'

interface Props {
  redirectVal?: string
}

const props = defineProps<Props>()
const emit = defineEmits(['showBox'])
const config = useRuntimeConfig()
const route = useRoute()
const router = useRouter()
const authStore = useAuth()
const openModal = ref(false)
const errorVal = ref<SocmedError>({})
const errorOTP = ref()
const form = reactive({ recipient: null })
const isCaptchaError = ref()
const redirect = useCookie('redirect', { path: '/', maxAge: 3600, domain: config.public.COOKIE_DOMAIN })
const rules = {
  recipient: {
    required: helpers.withMessage('Required', required),
    email: helpers.withMessage('Please use valid email', email),
  },
}
const v$ = useVuelidate(rules, form, { $autoDirty: false, $lazy: true })
const errorList = computed(() => [
  { id: 'ERR_INVALID_UNKNOWN', value: 'System Error' },
  { id: 'ERR_WITHIN_COOLDOWN_PERIOD', value: 'Please wait a few minutes and try again' },
  { id: 'ERR_INVALID_UNKNOWN', value: 'System Error' },
  { id: 'ERR_OAUTH_TOKEN', value: `${useTextCapitalize(route.query?.source ? route.query?.source.toString() : 'N/A')} authentication failed` },
  { id: 'ERR_LOGIN_FAILED', value: 'Login failed' },
  { id: 'ERR_REGISTER_FAILED', value: 'Registration failed' },
])

onMounted(async () => {
  // reset OTP Payload
  authStore.setOTPPayload({})

  // if there are error from socmed
  if (route.query?.error)
    setError(route.query?.source.toString(), route.query?.error.toString())

  // init captcha
  useInitRecaptcha()
  const recaptchaInstance = useReCaptcha()
  await recaptchaInstance?.recaptchaLoaded()
  const captchaToken = await recaptchaInstance?.executeRecaptcha('login')
  isCaptchaError.value = await authStore.checkCaptchaValid(captchaToken, true)
})

async function checkEmail() {
  errorVal.value = {}
  const isFormCorrect = await v$.value.$validate()
  if (isFormCorrect) {
    if (!isCaptchaError.value) {
      const data = await authStore.checkExist('email', form.recipient, false)
      if (data.status) {
        // if exist, redirect user to verify page
        // if dont exist, show email registration modal
        if (data.exist) {
          const otpRes = await authStore.sendOTP(form.recipient, 'login', 'email')
          authStore.setOTPPayload({ recipient: form.recipient, type: 'login', channel: 'email' })
          if (otpRes.status) {
            if (props.redirectVal)
              redirect.value = props.redirectVal
            router.push({ path: '/continue/verify', query: route.query })
          }
          else { setError('email', otpRes.msg) }
        }
        else {
          authStore.setOTPPayload({ recipient: form.recipient, type: 'register', channel: 'email' })
          openModal.value = true
          emit('showBox', false)
        }
      }
      else { setError('email', data.msg) }
    }
    else { setError('email', isCaptchaError.value) }
  }
  else { setError('email', v$.value.recipient.$errors[0]?.$message?.toString()) }
}

async function onRegister(code: string) {
  if (props.redirectVal)
    redirect.value = props.redirectVal
  const error = await authStore.onContinueEmail(code)
  errorOTP.value = error
}

function setError(typeVal: string, msg: string) {
  errorVal.value.type = typeVal
  errorVal.value.message = errorList.value.find(val => val.id === msg)?.value || msg
}

// need to convert & into * temporary because somehow it wont go through the url if i keep using &
// i will revert this again in callback func, rn this case happening in salary report public filter
async function continueLinkedin() {
  window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.public.LINKEDIN_ID}&redirect_uri=${config.public.SITEMAP_HOST}/callback/linkedin&scope=r_liteprofile%20r_emailaddress%20openid%20profile%20email&state=[previous_page=/continue,subdomain=${useGetSubdomain() || ''},redirect_to=${route.query.redirect_to || (!isEmpty(props.redirectVal) ? props.redirectVal.replace(/&/g, '*') : '')}]`, '_self')
}

async function continueGoogle() {
  window.open(`https://accounts.google.com/o/oauth2/auth?scope=openid%20email&response_type=code&access_type=offline&redirect_uri=${config.public.SITEMAP_HOST}/callback/google&client_id=${config.public.GOOGLE_CLIENT_ID}&state=[previous_page=/continue,subdomain=${useGetSubdomain() || ''},redirect_to=${route.query.redirect_to || (!isEmpty(props.redirectVal) ? props.redirectVal.replace(/&/g, '*') : '')}]`, '_self')
}
</script>

<template>
  <div>
    <ContinueOTPModal :open="openModal" :errors="errorOTP" :with-confirmation="true" @otp-complete="(code) => onRegister(code)" @open-modal="(data: boolean) => (openModal = data, emit('showBox', true))" />
    <p class="text-sm text-gray-600 mb-2">
      Create a Jobseeker account or Log in
    </p>
    <h1 class="text-2xl font-bold mb-10">
      Welcome to Epicareer
    </h1>

    <!-- Email section -->
    <div class="mb-6">
      <p class="text-sm font-semibold mb-2">
        Email Address
      </p>
      <input v-model="form.recipient" type="email" :class="`${errorVal?.type === 'email' ? 'input-danger' : 'input-primary'} input-base`" placeholder="yourname@email.com" @keyup.enter="checkEmail()">
      <BaseMoleculesInputError class="mt-2" :invalid-checker="errorVal?.type === 'email'" :error-val="errorVal?.message" />
    </div>
    <BaseAtomsButton size="md" classes="font-semibold w-full mb-10" @click="checkEmail()">
      Continue
    </BaseAtomsButton>

    <!-- Socmed section -->
    <div class="text-center space-y-4 mb-10">
      <p class="text-sm text-gray-600">
        Or
      </p>
      <div class="grid">
        <BaseAtomsButton size="md" class="bg-[#F8F9FC] hover:bg-[#F8F9FC] text-sm text-gray-900 w-full flex items-center" @click="continueGoogle()">
          <Icon name="logos:google-icon" class="w-5 h-5 mr-4" /> Continue with Google
        </BaseAtomsButton>
        <BaseMoleculesInputError class="mt-2 mx-auto" :invalid-checker="errorVal?.type === 'google'" :error-val="errorVal?.message" />
      </div>
      <div class="grid">
        <BaseAtomsButton size="md" class="bg-[#F8F9FC] hover:bg-[#F8F9FC] text-sm text-gray-900 w-full flex items-center" @click="continueLinkedin()">
          <Icon name="logos:linkedin-icon" class="w-5 h-5 mr-4" /> Continue with LinkedIn
        </BaseAtomsButton>
        <BaseMoleculesInputError class="mt-2 mx-auto" :invalid-checker="errorVal?.type === 'linkedin'" :error-val="errorVal?.message" />
      </div>
    </div>

    <!-- Bottom section -->
    <div class="text-gray-600 text-xs mb-10 text-center">
      By using Epicareer, you are agree with <br>Epicareer <a href="/terms-of-service" class="text-[#5843E5]" target="_blank">terms of service</a> and <a href="/privacy-policy" class="text-[#5843E5]" target="_blank">privacy policy</a>
    </div>

    <div class="text-sm flex items-center mx-auto py-6 sm:py-0">
      Are you Hiring?
      <a href="https://talent.epicareer.com/login" class="text-[#5843E5] font-semibold ml-2" target="_blank">Continue as Employer</a>
    </div>
  </div>
</template>
